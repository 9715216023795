export const Misc = {
  predictiaHomePage: 'http://www.predictia.es/',
  predictiaEmail: 'mailto:predictia@predictia.es',
};

export const WmsParams = {
  service: 'service=WMS',
  version: 'version=1.3.0',
  getLegendRequest: 'request=getLegendGraphic',
  getFeatureInfoRequest: 'request=getFeatureInfo',
  legendWidth: 'width=350',
  legendHeight: 'height=70',
  legendFormat: 'format=image/png',
  legendTransparent: 'transparent=true',
};

export const GAMeasurementID = 'G-XRG0JR8L5R';
export const CookiesEnabledKey = 'PredictiaShowcase::CookiesEnabled';
export const CookiesBannerShowKey = 'PredictiaShowcase::ShowCookieBanner';
