import React, { useState, useEffect } from 'react';

import { CloudIcon, SnowIcon, SunIcon, RainyCloudIcon } from 'components/svg-icon';

import './SplashAnimation.scss';

export const SplashAnimation = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setVisible(true);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    visible ? (
      <div className='splash__container'>
        <CloudIcon className='animate cloud' />
        <SnowIcon className='animate snow' />
        <SunIcon className='animate sun' />
        <RainyCloudIcon className='animate rainy' />
      </div>
    ) : <div />
  );
};
