import React from 'react';

import ReactGa from 'react-ga';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';

import { SplashAnimation } from 'components/splash-animation/SplashAnimation';

const Landing = React.lazy(() => import('landing/Landing'));

const FrostsApp = React.lazy(
  () => import(/* webpackChunkName: "frosts.app" */ 'apps/frosts/FrostsApp')
);
const FwiApp = React.lazy(
  () => import(/* webpackChunkName: "fwi.app" */ 'apps/fwi/FwiApp')
);
const WindsApp = React.lazy(
  () => import(/* webpackChunkName: "winds.app" */ 'apps/winds/WindsApp')
);
const SailingApp = React.lazy(
  () =>
    import(/* webpackChunkName: "sailing.app" */ 'apps/sailing-17/Sailing17App')
);
const SpainSummerLengthApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "spain-summer-length.app" */ 'apps/spain-summer-length/SpainSummerLengthApp'
    )
);
const CantabriaRemoteSensingApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "cantabria-remote-sensing.app" */ 'apps/cantabria-remote-sensing/CantabriaRemoteSensingApp'
    )
);
const SmartMapApp = React.lazy(
  () =>
    import(/* webpackChunkName: "smartmap.app" */ 'apps/smartmap/SmartMapApp')
);
const WeatherRadialsApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "weather-radials.app" */ 'apps/weather-radials/WeatherRadialApp'
    )
);
const WebGLCloudsApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "webgl-clouds.app" */ 'apps/webgl-clouds/WebGLCloudsApp'
    )
);
const MadridHeatWaveApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "madrid-heat-waves.app" */ 'apps/madrid-heat-wave/MadridHeatWaveApp'
    )
);

const D3HexVizApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "d3-hex-vix.app" */ 'apps/d3-hexagon-viz/D3HexagonVizApp'
    )
);

const EuropeSnowApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "europe-snow.app" */ 'apps/europe-snow/EuropeSnowApp'
    )
);

const LaRiojaWineApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "la-rioja-wine.app" */ 'apps/la-rioja-wine/LaRiojaWineApp'
    )
);

const UtciExplorerApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "utci-explorer.app" */ 'apps/utci-explorer/UtciExplorerApp'
    )
);

const EolicGenrationApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "eolic-generation.app" */ 'apps/eolic-generation/EolicGenerationApp'
    )
);

const EuropeSummerLengthApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "europe-summer-length.app" */ 'apps/europe-summer-length/EuropeSummerLengthApp'
    )
);

const GlobalFwiCausalityApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "global-fwi-causality.app" */ 'apps/global-fwi-causality/GlobalFwiApp'
    )
);

const GlobalFwiCorrelationApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "global-fwi-correlation.app" */ 'apps/global-fwi-correlation/GlobalFwiApp'
    )
);

const PalmTreeDetectionApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "palm-tree-detection.app" */ 'apps/palm-tree-detection/PalmTreeDetectionApp'
    )
);

export const Router = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    ReactGa.pageview(pathname);
  }, [pathname]);

  return (
    <React.Suspense fallback={<SplashAnimation />}>
      <Switch>
        <Route path='/frosts'>
          <FrostsApp />
        </Route>
        <Route path='/fwi'>
          <FwiApp />
        </Route>
        <Route path='/winds'>
          <WindsApp />
        </Route>
        <Route path='/sailing-17'>
          <SailingApp />
        </Route>
        <Route path='/spain-summer-length'>
          <SpainSummerLengthApp />
        </Route>
        <Route path='/smartmap-santander'>
          <SmartMapApp />
        </Route>
        <Route path='/weather-radials'>
          <WeatherRadialsApp />
        </Route>
        <Route path='/webgl-clouds'>
          <WebGLCloudsApp />
        </Route>
        <Route path='/cantabria-remote-sensing'>
          <CantabriaRemoteSensingApp />
        </Route>
        <Route path='/hexembles'>
          <D3HexVizApp />
        </Route>
        <Route path='/heat-wave-explorer'>
          <MadridHeatWaveApp />
        </Route>
        <Route path='/europe-snow'>
          <EuropeSnowApp />
        </Route>
        <Route path='/la-rioja-wine'>
          <LaRiojaWineApp />
        </Route>
        <Route path='/utci-explorer'>
          <UtciExplorerApp />
        </Route>
        <Route path='/eolic-generation'>
          <EolicGenrationApp />
        </Route>
        <Route path='/europe-summer-length'>
          <EuropeSummerLengthApp />
        </Route>
        <Route path='/global-fwi-causality'>
          <GlobalFwiCausalityApp />
        </Route>
        <Route path='/global-fwi'>
          <GlobalFwiCorrelationApp />
        </Route>
        <Route path='/palm-tree-detection'>
          <PalmTreeDetectionApp />
        </Route>
        <Route path='/' exact>
          <Landing />
        </Route>
        <Redirect to='/' />
      </Switch>
    </React.Suspense>
  );
};
