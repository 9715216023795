import React from 'react';
import ReactDOM from 'react-dom';

import ReactGa from 'react-ga';

import { GAMeasurementID } from 'core/constants';

import App from './App';

import * as serviceWorker from './serviceWorker';

import 'ol/ol.css';
import './index.scss';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

ReactGa.initialize(GAMeasurementID, {
  debug: process.env.NODE_ENV === 'development',
});

serviceWorker.register();
