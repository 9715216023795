import React from 'react';

import 'assets/styles/partials/_links.scss';

export const Link = ({
  href,
  target = '_blank',
  className,
  children
}: React.PropsWithChildren<{ href: string; target?: string, className?: string }>) => (
  <a
    className={`viewer-link ${className || ''}`}
    href={href}
    rel='noopener noreferrer'
    target={target}
  >
    {children}
  </a>
);
