import { useLocalStorage } from 'hooks/useLocalStorage';
import React from 'react';

import { CookiesBannerShowKey, CookiesEnabledKey } from 'core/constants';

import './CookieBanner.scss';
import { CSSTransition } from 'react-transition-group';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components/button/Button';
import { Link } from 'components/link/Link';

export const CookieBanner = () => {
  const { t } = useTranslation('commons');

  const [showBanner, setShowBanner] = React.useState(false);

  const [showBannerPreference, setShowBannerPreference] = useLocalStorage(
    CookiesBannerShowKey,
    true
  );
  const [, setAllowCookies] = useLocalStorage(CookiesEnabledKey, false);

  React.useEffect(() => {
    let isMounted = true,
      timeoutId: any;
    if (showBannerPreference) {
      timeoutId = setTimeout(() => {
        if (isMounted) {
          setShowBanner(true);
        }
      }, 4000);
    }

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAccept = () => {
    setShowBannerPreference(false);
    setAllowCookies(true);
    setShowBanner(false);
  };

  const onReject = () => {
    setShowBannerPreference(false);
    setAllowCookies(false);
    setShowBanner(false);
  };

  return (
    <CSSTransition
      classNames='cookies-banner-transition'
      timeout={150}
      unmountOnExit
      in={showBanner}
    >
      <div className='cookies-banner'>
        <div className='text-container'>
          <span className='cookies-text'>
            {t('commons:cookiesBanner.text')}
          </span>
          <span className='cookies-text'>
            <Trans i18nKey='commons:cookiesBanner.link'>
              <Link
                target='__blank'
                href='https://predictia.es/es/cookies'
              ></Link>
            </Trans>
          </span>
        </div>
        <div className='button-row'>
          <button onClick={onReject}>
            {t('commons:cookiesBanner.reject')}
          </button>
          <button onClick={onAccept}>
            {t('commons:cookiesBanner.accept')}
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};
