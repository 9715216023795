import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';

export const EPSG_4326 = '+proj=longlat +datum=WGS84 +no_defs';

const projections: any[] = [
  {
    'EPSG:4326': EPSG_4326,
  },
];

export const registerProjections = () => {
  projections.forEach((p) => {
    const K = Object.keys(p)[0] as any;
    const P = p[K];
    proj4.defs(K, P);
  });
  register(proj4);
};
