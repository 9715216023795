import { createActionCreator, createReducer } from 'deox';
import { View } from 'ol';

//#region - ACTION NAMES
/*
Usar cuando haya multiples mapas con Openlayers. 
La forma mas optima es compartir la misma referencia de
la propiedad View del mapa.
*/
const SET_MAP_VIEW = '[Map] :: Set map view';

/**
 * Accion para seleccionar la capa que se quiere ver en el mapa.
 * Si se usa en un mapa que soporte varias capas, esta acción reseteará
 * el array de capas y dejara solo activa la capa que se pase como argumento.
 */
const SET_LAYER = '[Map] :: Set layer';

/**
 * Añadir una nueva capa al array de capas visibles del mapa.
 */
const ADD_LAYER = '[Map] :: Add layer';

/**
 * Eliminar una capa del array de capas visibles del mapa.
 */
const REMOVE_LAYER = '[Map] :: Remove layer';

//#endregion

//#region - ACTION CREATORS

export const setMapView = createActionCreator(
  SET_MAP_VIEW,
  resolve => ({ mapView }: { mapView: View }) => resolve(mapView)
);

export const setLayer = createActionCreator(
  SET_LAYER,
  resolve => ({ layer }: { layer: {} }) => resolve(layer)
);

export const addLayer = createActionCreator(
  ADD_LAYER,
  resolve => ({ layer }: { layer: {} }) => resolve(layer)
);

export const removeLayer = createActionCreator(
  REMOVE_LAYER,
  resolve => ({ layer }: { layer: {} }) => resolve(layer)
);

//#endregion

//#region - STATE DECLARATION

export interface MapState {
  mapView: View;
  layers: any[];
}

const initialState: MapState = {
  mapView: new View({
    center: [0, 0],
    zoom: 4
  }),
  layers: []
};

//#endregion

//#region - REDUCERS

export const mapReducer = createReducer(initialState, handle => [
  handle(setMapView, (state, { payload }) => ({
    ...state,
    mapView: payload
  })),

  handle(setLayer, (state, { payload }) => ({
    ...state,
    layers: [payload]
  })),

  handle(addLayer, (state, { payload }) => {
    const newLayers = state.layers.concat(payload);
    return {
      ...state,
      layers: newLayers
    };
  }),

  handle(removeLayer, (state, { payload }) => {
    const newLayers = state.layers.filter((ly: any) => ly !== payload);
    return {
      ...state,
      layers: newLayers
    };
  })
]);

//#endregion
