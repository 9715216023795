import { createStore, combineReducers, Store } from 'redux';
import { mapReducer, MapState } from './map/map.store';

export interface IStore extends Store {
  map: MapState
};

export const rootReducer = combineReducers({
  map: mapReducer
});

export default createStore(rootReducer);
