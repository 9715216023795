import i18next from 'i18next';
import i18nextBackend from 'i18next-xhr-backend';
import i18nextLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
  .use(i18nextBackend)
  .use(i18nextLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS: 'commons',
    nsSeparator: ':',
    fallbackNS: 'commons',
    cleanCode: true,
    load: 'languageOnly',
    partialBundledLanguages: true,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    debug: process.env.NODE_ENV === 'development',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a']
    },
    ns: 'commons'
  });

export default i18next;
