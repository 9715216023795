import React, { Suspense } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import ReactGa from 'react-ga'

import Store from 'store';
import { CookiesEnabledKey } from 'core/constants';
import i18n from 'core/i18n';
import { registerProjections } from 'core/projs';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { GA } from 'core/GAnalytics';

import { SplashAnimation } from 'components/splash-animation/SplashAnimation';
import { Router } from 'components/router/Router';
import { CookieBanner } from 'components/cookie-banner/CookieBanner';

import './App.scss';

registerProjections();

function App() {
  
  const [allowCookies] = useLocalStorage(CookiesEnabledKey, true);  

  React.useEffect(() => {
    if (allowCookies && process.env.NODE_ENV !== 'development') {
      GA.enable();
    } else {
      GA.disable();
    }
  }, [allowCookies]);

  return (
    <Suspense fallback={<SplashAnimation />}>
      <BrowserRouter>
        <Provider store={Store}>
          <I18nextProvider i18n={i18n}>
            <div className='App'>
              <Router />
              <CookieBanner />
            </div>
          </I18nextProvider>
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
